<template>
  <click-to-edit
    v-model="scope.row.scale.unit"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.unit')"
    placeholder=""
    @input="saveElementPropChange(scope.row, 'scale', {unit:$event})"
  >
  </click-to-edit>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-unit-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {}
}

</script>

<style>

</style>
